

function addMessage(text, type, setMessages, clear=false){
    if (clear===true) {
        setMessages([]);
    }
    const message = {text: text, type: type};
    setMessages((prevMessages) => [...prevMessages, message]);

    // auto scroll
    let chat_area_main = document.querySelector('.chat-area-main');
    setTimeout(() => {
        chat_area_main.scrollTop = chat_area_main.scrollHeight;
        
    }, 100);
};

export default addMessage;