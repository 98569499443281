const isUserSpeaking = (analyser) => {
    
    // Audio ma'lumotlarini tahlil qilish uchun analyzer node
    const frequencyData = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(frequencyData);

    const threshold = 160; // ovoz darajasi

    const isSpeaking = frequencyData.some(value => {
        return value > threshold
    });

    return isSpeaking;
};

export default isUserSpeaking;