
const socket = new WebSocket('wss://backend.diyoraai.uz/v2/tts-ws/');

function TTS(text){
    // text = text.replace("O'", "Oʻ");
    // text = text.replace("o'", "oʻ");
    // text = text.replace("G'", "Gʻ");
    // text = text.replace("g'", "gʻ");
    socket.onopen = function(event){
        console.log("websocket connected .........")
    }
    if (text){
        socket.send(text);
    }
  
    socket.onmessage = function(event) {
        if (typeof event.data === 'string') {
            
            if (event.data === 'TTS processing complete') {
                console.log('TTS processing complete');
            }
        } else {
            // Handle binary audio data
            let audioBlob = new Blob([event.data], { type: 'audio/wav' });
            const audioUrl = URL.createObjectURL(audioBlob);
            let audioplay = document.getElementById("audioplay");
            audioplay.src = audioUrl;
            audioplay.play();
           
        }
    };

    socket.onerror = function(error) {
        console.error('WebSocket error:', error);
    };

    socket.onclose = function(event) {
        console.log('WebSocket connection closed', event);
    };
}


export default TTS;