import { useEffect, useRef, useState } from 'react';
import TTS from './TTS';
import audioVisualizer from './images/audio_visualizer.gif'
import recordingGif from "./images/recording.gif";
import sendMessageIcon from './images/send_icon.png';
import waitingGif from './images/wait.gif'
import isUserSpeaking from './utils/isUserSpeaking';
import addMessage from './utils/addMessage';
import KeyWordRecognition from './utils/KeyWordRecognition';
import defaultFace from './images/visualizer.png';
import speakingFace from './images/visualizer.gif';


const socket = new WebSocket(`wss://backend.diyoraai.uz/v3/stt-ws`);

const STT = ({ chatbotSocket, setMessages }) => {
    
    const recognitionRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const whenAudioFinish = useRef(null);
    const silenceDuration = 1500;
    const waitingTimeAfterAudioPlayEnd = 5000;
    let silenceStart = null;
    let userSpeaking = false;
    let chunks = [];


    useEffect(() => {
        const face = document.getElementById('face');
        const stateIcon = document.getElementById('state-icon');
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
                mediaRecorderRef.current = mediaRecorder;
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                const analyser = audioContext.createAnalyser();
                const source = audioContext.createMediaStreamSource(stream);
                source.connect(analyser);

                mediaRecorder.onstart = () =>{
                    stateIcon.src = recordingGif;
                    const recordingSound = document.getElementById('recording-sound')
                    recordingSound.play();
                };

                mediaRecorder.ondataavailable = (event) => {
                    
                    if (Date.now() - whenAudioFinish.current > waitingTimeAfterAudioPlayEnd && whenAudioFinish.current){
                        console.log(whenAudioFinish, "shart bo'yicha o'chirilmoqda")
                        mediaRecorder.stop();
                    }

                    if (event.data.size > 0 && socket.readyState === WebSocket.OPEN) {
                        const isSpeaking = isUserSpeaking(analyser)
                        // console.log(isSpeaking, "isspeaking")
                        if (isSpeaking){
                            chunks.push(event.data);
                            silenceStart = null
                            userSpeaking = true;
                            whenAudioFinish.current = Date.now();
                        }
                        else {
                           
                            if (silenceStart === null && userSpeaking){
                                silenceStart = Date.now();
                                chunks.push(event.data);
                                
                            } else if (silenceStart != null && Date.now() - silenceStart > silenceDuration) {
                                mediaRecorder.stop();
                                userSpeaking = false;
                                silenceStart = null;
                                const combinedBlob = new Blob(chunks, { type: 'audio/webm' });
                                chunks = [];
                                console.log("sttga jo'natildi   ")
                                socket.send(combinedBlob);
                                
                            }else{
                                chunks.push(event.data);
                            }
                            
                        };
                        
        
                    }
                   
                };
              
                mediaRecorder.onstop = () => {
                    chunks = [];
                    stateIcon.src = sendMessageIcon;
                    const stopRecordingSound = document.getElementById('stop-recording-sound')
                    stopRecordingSound.play();
                    
                };

            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
            });


        let audioplay = document.getElementById("audioplay");
        
        audioplay.addEventListener('play', () => {
            face.src = speakingFace;
            stateIcon.src = audioVisualizer;
            mediaRecorderRef.current.stop();
        });

        audioplay.addEventListener('ended', () => {
            face.src = defaultFace;
            if (mediaRecorderRef.current.state === "inactive"){
                mediaRecorderRef.current.start(300);
            }
            stateIcon.src = recordingGif;
            whenAudioFinish.current = Date.now();
    
        }); 

        // socket
        socket.onmessage = function(event){
            stateIcon.src = waitingGif;
            if (event.data === "Speech not understood"){
                addMessage("Tushunolmadim, qayta gapira olasizmi?", '', setMessages)
                TTS("Tushunolmadim, qayta gapira olasizmi?")
            } else{
                addMessage(event.data, "owner", setMessages);
                chatbotSocket.send(event.data);
            }
        }
        // Xatolik yuz berganda
        socket.onerror = function(error) {
            console.error('WebSocket xatoligi:', error);
        };
        
        // Ulanish yopilganda
        socket.onclose = function(event) {
            console.log('WebSocket ulanishi yopildi', event);
        };
        
        return () => {
            if (recognitionRef.current) {
                recognitionRef.current.stop();
            }
        };
    }, []);

    return (
        <section>
            <KeyWordRecognition mediaRecorderRef={mediaRecorderRef} chatbotSocket={chatbotSocket} setMessages={setMessages} whenAudioFinish={whenAudioFinish}/>
        </section>
    );
};

export default STT;
