
import ChatArea from './ChatArea';
import HiddenAudioPlayer from './AudioPlayer';
import './App.css';
import { useState, useRef } from 'react';

const socket = new WebSocket("wss://bot.diyoraai.uz/chatbot/anorbank");
// const socket = new WebSocket("ws://127.0.0.1:8000/ws/chat/1/");
// const socket = new WebSocket("ws://45.91.200.209:8001/chatbot/MB");

function App() {

  const [messages, setMessages] = useState([])
  const audioRef = useRef(null);


  const playAudio = () => {
      let audioplay = document.getElementById("audioplay");
      audioplay.play();
  }; 

  return (
    <div className="app">
      <div className="wrapper">
          <HiddenAudioPlayer 
            src={""}
            audioRef={audioRef}/>
          <ChatArea 
            messages={messages} 
            setMessages={setMessages} 
            socket={socket}
            playAudio={playAudio}/>
            
      </div>
    </div>
  );
}

export default App;
