import diyoraAvatar from "./images/diyoraAvatar.gif"
import userIcon from "./images/usericon.png"

function Message({ messages, messagesEndRef }) {
    
    return messages.map((message, index) => (
        <div  className={`chat-msg ${message.type}`} key={index}>
            <div className="chat-msg-profile">
                <img className="chat-msg-img" src={ message.type === 'owner' ? userIcon : diyoraAvatar } alt="" />
            </div>
            <div className="chat-msg-content">
                <div className="chat-msg-text">{ message.text }</div>
            </div>
       </div>
    ));
}

export default Message;