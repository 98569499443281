import sendMessageIcon from "./images/send_icon.png"
import { useState } from "react";
import addMessage from "./utils/addMessage";



function ChatAreaFooter({ socket, setMessages }){
    const [inputText, setInputText] = useState('');
    

    const onChangeHandle = (event) => {
        setInputText(event.target.value);
    }
    const handleOnClick = () => {
        addMessage(inputText, 'owner', setMessages);
        socket.send(inputText);
        setInputText("")
        
    }
    const handleEnterBottonDown = (event) => {
        if (event.key === "Enter"){
            document.getElementById("state-icon").click();
            document.getElementById("chat-input-text").value = "";

        }
    }
    return (  
        <div className="chat-area-footer">
            <input id="chat-input-text" onKeyDown={handleEnterBottonDown} onChange={onChangeHandle} type="text" placeholder="Shu yerga matn kiriting..." />
            <img onClick={handleOnClick} id="state-icon" src={ sendMessageIcon } alt="" />
            {/* <button id="start-button" style={{"display": "none"}}></button> */}
        </div> 
    )
}

export default ChatAreaFooter;