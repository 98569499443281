import React, { useEffect } from 'react';
import recordingSound from "./audios/recording.mp3"
import stopRecordingSound from "./audios/stop_recording.mp3"


const HiddenAudioPlayer = ({ src, audioRef }) => {

  return (
    <div>
      <audio id='audioplay' ref={audioRef} src={src} style={{ display: 'none' }} controls />
      <audio className="recording-sound" id="recording-sound" src={recordingSound}></audio>
      <audio className="recording-sound" id="stop-recording-sound" src={stopRecordingSound}></audio>
    </div>
  );
};

export default HiddenAudioPlayer;
